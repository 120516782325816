var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('StudieplanDetailsPage'), _c('v-row', [_c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('BaseSheet', {
    attrs: {
      "outlined": false,
      "rounded": "lg"
    }
  }, [_c('v-card-actions', [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push("/home/gjennomforingsplaner/ny/".concat(_vm.$route.params.id));
      }
    }
  }, [_vm._v("Opprett gjennomføringsplan")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    }
  }, [_vm._v("Avbryt")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push("/studieplaner/studieplan/".concat(_vm.$route.params.id));
      }
    }
  }, [_vm._v("Rediger studieplan")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }